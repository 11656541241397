<template>
  <div class="sku-setting">
    <div class="sku-item-list" v-for="(item, index1) in sku_item_list" :key="index1">
      <div class="sku_item">
        <div class="flex">
          <span class="sku-label">{{ $t(`规格名`) }}：</span>
          <span>{{ item.styleTypeName }}</span>
          <span
            v-if="
              item.styleValueList.length !== 0 &&
              item.styleValueList.length > item.value_list.length &&
              is_edit
            "
            class="text-info ml10 h40"
            @click="addSubChildren(item, false)"
          >
            {{ $t(`添加规格值`) }}
          </span>
          <span
            v-if="is_edit && item.textAvailable"
            class="text-info ml10 h40"
            @click="addSubChildren(item, true)"
          >
            {{ $t(`添加自定义规格值`) }}
          </span>
        </div>
        <div class="flex">
          <span class="sku-label" v-if="item.value_list.length !== 0">{{ $t(`规格值`) }}：</span>
          <div class="children-list" :class="{ wrap: item.imageAvailable }">
            <div v-for="(elem, index2) in item.value_list" :key="index2" class="sub-child">
              <div class="select-content flex">
                <SearchSelect
                  :list="item.styleValueList | notSelected2(sku_item_list)"
                  :textAvailable="elem.textAvailable"
                  :value.sync="elem.styleTypeName"
                  :deletable="is_edit"
                  @handleChange="changeSubChildren(index1, index2, $event)"
                ></SearchSelect>
                <a-icon type="rest" class="ml10 trash" @click="deleteSkuItem(index1, index2)" />
                <div class="ml10 text-info" v-if="item.imageAvailable">
                  <span v-if="elem.hide" @click="toggleImage(elem, false)">{{ $t('展开') }}</span>
                  <span v-else @click="toggleImage(elem, true)">{{ $t('收起') }}</span>
                </div>
              </div>
              <a-card class="image-list" v-show="item.imageAvailable && !elem.hide">
                <upload-image
                  :disabled="!is_edit"
                  @getImageId="getDelImageId(elem)"
                  :multiple="8"
                  :imageList.sync="elem.uploadImageUrlList"
                ></upload-image>
                <div>
                  <div class="tips">
                    {{ $t('规格的商品图必填，尺寸在500×500到2000×2000px之间') }}
                    <span class="red">*</span>
                  </div>
                  <div
                    class="text-info"
                    :class="{ 'text-disabled': !is_edit }"
                    v-if="index2 !== 0"
                    @click="copyPrevSkuImage(item.value_list, index2)"
                  >
                    {{ $t('复制上个SKU图片') }}
                  </div>
                </div>
                <a-modal :visible="previewVisible" :footer="null" @cancel="previewVisible = false">
                  <img alt="example" style="width: 100%" :src="previewImage" />
                </a-modal>
              </a-card>
            </div>
          </div>
        </div>
        <a-divider style="margin: 12px 0 2px" />
      </div>
    </div>
    <div class="sku-table table-content">
      <div class="table-header" v-if="page != 'platformGoodsDetail'">
        <span class="btn" @click="copySku('price')">
          <a-icon type="link" />
          {{ $t('仅复制第一个SKU价格到其他') }}
        </span>
        <span class="btn" @click="copySku('unit')">
          <a-icon type="link" />
          {{ $t('仅复制第一个SKU单位到其他') }}
        </span>
        <span class="btn" @click="copySku('packageWeight')">
          <a-icon type="link" />
          {{ $t('仅复制第一个SKU重量到其他') }}
        </span>
        <span class="btn" @click="copySku('packageLength')">
          <a-icon type="link" />
          {{ $t('仅复制第一个SKU长度到其他') }}
        </span>
        <span class="btn" @click="copySku('packageWidth')">
          <a-icon type="link" />
          {{ $t('仅复制第一个SKU宽度到其他') }}
        </span>
        <span class="btn" @click="copySku('packageHeight')">
          <a-icon type="link" />
          {{ $t('仅复制第一个SKU高度到其他') }}
        </span>
        <span class="btn" @click="copySku('containerQuantity')">
          <a-icon type="link" />
          {{ $t('仅复制第一个SKU箱规到其他') }}
        </span>
      </div>
      <div class="table-body">
        <a-table
          v-if="tableData.length"
          bordered
          :columns="tableColumns"
          :data-source="tableData"
          :pagination="false"
          :rowKey="'styleTypeName'"
          :scroll="{ x: 1800 }"
        >
          <span slot="customTitle">{{ customTitle }}</span>
          <span slot="styleTypeName" slot-scope="text, scope, index">
            <span>{{ text }}</span>
            <a-icon v-if="is_edit" type="delete" class="delete-icon" @click="deleteRow(index)" />
          </span>
          <span slot="customCode">
            <i class="red">*</i>
            {{ $t(`商家编码`) }}
          </span>
          <span slot="customBar">
            <i class="red">*</i>
            {{ $t(`商品条码`) }}
          </span>
          <span slot="customSkuName">
            <i class="red">*</i>
            {{ $t(`SKU名称`) }}
          </span>
          <span slot="customBrandSku">{{ $t(`品牌款号`) }}</span>
          <span slot="customSupplierSku">{{ $t(`供应商款号`) }}</span>
          <span slot="customCompositionOrMaterial">{{ $t(`成分/材质`) }}</span>
          <span slot="customPrice">
            <i class="red">*</i>
            {{ $t(`售价`) }}
          </span>
          <span slot="unit">{{ $t(`单位`) }}</span>
          <span slot="packageWeight">
            <!-- <i class="red">*</i> -->
            {{ $t(`重量`) }}(kg)
          </span>
          <span slot="packageLength">
            <!-- <i class="red">*</i> -->
            {{ $t(`长`) }}(cm)
          </span>
          <span slot="packageWidth">
            <!-- <i class="red">*</i> -->
            {{ $t(`宽`) }}(cm)
          </span>
          <span slot="packageHeight">
            <!-- <i class="red">*</i> -->
            {{ $t(`高`) }}(cm)
          </span>
          <span slot="packageBoxSize">{{ $t(`箱规`) }}</span>
          <span slot="skuCode" slot-scope="text, scope">
            <a-input
              v-model="scope.skuCode"
              :disabled="!is_edit || (pageType === 'edit' && !!scope.skuId)"
              @input="
                (e) => {
                  handleInput(e, scope, 'skuCode')
                }
              "
              @blur="onChange"
            ></a-input>
          </span>
          <span slot="barcode" slot-scope="text, scope">
            <a-input
              v-model="scope.barcode"
              :disabled="!is_edit || (pageType === 'edit' && !!scope.skuId)"
              @input="
                (e) => {
                  handleInput(e, scope, 'barcode')
                }
              "
              @blur="onChange"
            ></a-input>
          </span>
          <span slot="brandSku" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.brandSku" :disabled="!is_edit"></a-input>
          </span>
          <span slot="supplierSku" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.supplierSku" :disabled="!is_edit"></a-input>
          </span>
          <span slot="compositionOrMaterial" slot-scope="text, scope">
            <a-input
              @blur="onChange"
              v-model="scope.compositionOrMaterial"
              :disabled="!is_edit"
            ></a-input>
          </span>
          <span slot="price" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.price" :disabled="!is_edit"></a-input>
          </span>
          <span slot="minPrice" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.minPrice" :disabled="!is_edit"></a-input>
          </span>
          <span slot="sampleQuantity" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.sampleQuantity" :disabled="!is_edit"></a-input>
          </span>
          <span slot="unit" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.unit" :disabled="!is_edit" />
          </span>
          <span slot="packageWeight" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.packageWeight" :disabled="!is_edit" />
          </span>
          <span slot="packageLength" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.packageLength" :disabled="!is_edit" />
          </span>
          <span slot="packageWidth" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.packageWidth" :disabled="!is_edit" />
          </span>
          <span slot="packageHeight" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.packageHeight" :disabled="!is_edit" />
          </span>
          <span slot="packageBoxSize" slot-scope="text, scope">
            <a-input @blur="onChange" v-model="scope.containerQuantity" :disabled="!is_edit" />
          </span>
          <span slot="ingredients" slot-scope="text, scope" v-if="showPropertiesBtn">
            <div class="text-link" @click="showProperties(scope)">{{ $t('查看配料表') }}</div>
          </span>
        </a-table>
      </div>
      <div class="table-footer" style="padding: 0">
        <div class="unit-content mt10" style="width: 100%">
          <a-table
            v-if="unitList.length > 0"
            size="small"
            :columns="columns"
            :data-source="unitList"
            :rowKey="(record, index) => index"
            :pagination="false"
          >
            <span slot="saleUnitTitle">
              <a-popover>
                <template slot="content">
                  {{ $t('下单、发货、销售退货') }}
                </template>
                <div>
                  {{ $t('销售单位') }}
                  <i class="fa-regular fa-circle-question"></i>
                </div>
              </a-popover>
            </span>
            <span slot="procurementUnitTitle">
              <a-popover>
                <template slot="content">
                  {{ $t('采购入库、采购退货') }}
                </template>
                <div>
                  {{ $t('采购单位') }}
                  <i class="fa-regular fa-circle-question"></i>
                </div>
              </a-popover>
            </span>
            <span slot="inventoryUnitTitle">
              <a-popover>
                <template slot="content">
                  {{ $t('移库、补货、盘点') }}
                </template>
                <div>
                  {{ $t('库存单位') }}
                  <i class="fa-regular fa-circle-question"></i>
                </div>
              </a-popover>
            </span>
            <template slot="unitName" slot-scope="text, record">
              <SelectUnit
                style="width: 100px"
                :code.sync="record.unitName"
                @change="
                  (data) => {
                    changeUnitHandle(data, record)
                  }
                "
              />
            </template>
            <template slot="conversionRate" slot-scope="text, record, index">
              <template v-if="record.unitNameFull">
                <div>{{ $t('转换率') }}: 1{{ record.unitNameFull }}=</div>
                <a-input
                  type="number"
                  class="mt6"
                  style="width: 150px"
                  :disabled="index === 0"
                  :addon-after="baseUnit"
                  v-model="record.conversionRate"
                />
              </template>
            </template>
            <template slot="action" slot-scope="text, record, index">
              <a v-if="index > 0" @click="delUnit(index)">{{ $t('删除') }}</a>
            </template>
            <template v-for="col in unitTypes" v-slot:[col.key]="text, record, index">
              <div :key="col.key">
                <template v-if="col.key === 'baseUnit'">
                  <a-checkbox disabled v-if="index === 0" v-model="record[col.key]" />
                </template>
                <a-checkbox
                  v-else
                  v-model="record[col.key]"
                  @change="changeCheck(col.key, record)"
                />
              </div>
            </template>
          </a-table>
          <div class="add-unit flex-c-c" @click="addUnitList" v-if="isStore">
            <a-icon type="plus" />
            {{ $t('添加单位') }}
          </div>
        </div>
      </div>
    </div>
    <PropertiesModal ref="propertiesModal" :skuInfo="currentSku" @success="changeSuccess" />
  </div>
</template>

<script>
import SearchSelect from '@component/searchSelect'
import deepClone from '@common/deepClone'
import uploadImage from '@component/uploadImage'
import PropertiesModal from '@component/propertiesModal'
import SelectUnit from '@component/selectUnit'
import debounce from '@common/debounce'
export default {
  name: 'skuSetting',
  props: [
    'formData',
    'is_edit',
    'pageType',
    'defaultTableData',
    'styleTypeList',
    'skuAttributes',
    'propsTableData',
    'page',
    'speed',
    'unitList',
  ],
  components: {
    SearchSelect,
    uploadImage,
    PropertiesModal,
    SelectUnit,
  },
  mounted() {
    this.tableData = deepClone(this.originalData)
  },
  computed: {
    isStore() {
      //是门店类型
      return this.$store.getters.isStore
    },
    originalData() {
      let obj = {}
      if (this.skuAttributes) {
        this.skuAttributes.forEach((item) => {
          obj[item.name] = ''
        })
      } else {
        obj = {
          originPrice: '',
          price: '',
          unit: null,
          barcode: '',
          skuCode: '',
          skuImageList: [],
          brandSku: '',
          supplierSku: '',
          compositionOrMaterial: '',
          styleType: '',
          styleTypeName: this.$t(`无规格`),
          properties: {},
        }
      }
      return [obj]
    },
    tableColumns() {
      let list = [
        {
          dataIndex: 'styleTypeName',
          key: 'styleTypeName',
          slots: { title: 'customTitle' },
          scopedSlots: { customRender: 'styleTypeName' },
          width: 150,
        },
        {
          dataIndex: 'skuCode',
          key: 'skuCode',
          slots: { title: 'customCode' },
          scopedSlots: { customRender: 'skuCode' },
          width: 200,
        },
        {
          dataIndex: 'barcode',
          key: 'barcode',
          slots: { title: 'customBar' },
          scopedSlots: { customRender: 'barcode' },
          width: 200,
        },
        {
          dataIndex: 'price',
          key: 'price',
          slots: { title: 'customPrice' },
          scopedSlots: { customRender: 'price' },
        },
        {
          title: this.$t('最低售价'),
          dataIndex: 'minPrice',
          scopedSlots: { customRender: 'minPrice' },
        },
        // {
        //   dataIndex: 'unit',
        //   key: 'unit',
        //   slots: { title: 'unit' },
        //   scopedSlots: { customRender: 'unit' },
        // },
        {
          dataIndex: 'packageWeight',
          key: 'packageWeight',
          slots: { title: 'packageWeight' },
          scopedSlots: { customRender: 'packageWeight' },
        },
        {
          dataIndex: 'packageLength',
          key: 'packageLength',
          slots: { title: 'packageLength' },
          scopedSlots: { customRender: 'packageLength' },
        },
        {
          dataIndex: 'packageWidth',
          key: 'packageWidth',
          slots: { title: 'packageWidth' },
          scopedSlots: { customRender: 'packageWidth' },
        },
        {
          dataIndex: 'packageHeight',
          key: 'packageHeight',
          slots: { title: 'packageHeight' },
          scopedSlots: { customRender: 'packageHeight' },
        },
        {
          dataIndex: 'containerQuantity',
          key: 'containerQuantity',
          slots: { title: 'packageBoxSize' },
          scopedSlots: { customRender: 'packageBoxSize' },
        },
        {
          dataIndex: 'brandSku',
          key: 'brandSku',
          slots: { title: 'customBrandSku' },
          scopedSlots: { customRender: 'brandSku' },
        },
        {
          dataIndex: 'supplierSku',
          key: 'supplierSku',
          slots: { title: 'customSupplierSku' },
          scopedSlots: { customRender: 'supplierSku' },
        },
        {
          dataIndex: 'compositionOrMaterial',
          key: 'compositionOrMaterial',
          slots: { title: 'customCompositionOrMaterial' },
          scopedSlots: { customRender: 'compositionOrMaterial' },
        },
      ]
      if (this.showPropertiesBtn) {
        list.push({
          title: this.$t('配料表'),
          dataIndex: 'ingredients',
          key: 'ingredients',
          scopedSlots: { customRender: 'ingredients' },
          fixed: 'right',
          width: 120,
        })
      }
      return list
    },
    customTitle() {
      if (!this.sku_item_list.length) {
        return this.$t(`无规格`)
      }
      let title = []
      this.sku_item_list.forEach((item) => {
        if (item.value_list.length) {
          title.push(item.styleTypeName)
        }
      })
      return title.length > 0 ? title.join('\\') : this.$t(`无规格`)
    },
    isRawMaterial() {
      //是原料
      return this.formData.goodsType === 'RAW_MATERIAL'
    },
    needProcess() {
      return ['FRESHLY_MADE_AND_SOLD', 'STOCK_UP_FOR_SALE'].includes(this.formData.saleType)
    },
    showPropertiesBtn() {
      return this.pageType === 'edit' && this.needProcess
    },
    unitTypes() {
      return [
        { key: 'baseUnit', title: this.$t('基准单位') },
        { key: 'saleUnit', title: this.$t('销售单位') },
        { key: 'procurementUnit', title: this.$t('采购单位') },
        { key: 'inventoryUnit', title: this.$t('库存单位') },
        { key: 'otherUnit', title: this.$t('其它单位') },
      ]
    },
    columns() {
      let list = [
        {
          title: this.$t('单位'),
          key: 'unitName',
          scopedSlots: { customRender: 'unitName' },
          width: 80,
        },
        {
          title: this.$t('转换率'),
          key: 'conversionRate',
          scopedSlots: { customRender: 'conversionRate' },
          width: 200,
        },
        {
          title: this.$t('基准单位'),
          scopedSlots: { customRender: 'baseUnit' },
        },
        {
          scopedSlots: { customRender: 'saleUnit' },
          slots: { title: 'saleUnitTitle' },
        },
        {
          scopedSlots: { customRender: 'procurementUnit' },
          slots: { title: 'procurementUnitTitle' },
        },
        {
          scopedSlots: { customRender: 'inventoryUnit' },
          slots: { title: 'inventoryUnitTitle' },
        },
        {
          title: this.$t('其它单位'),
          scopedSlots: { customRender: 'otherUnit' },
        },
        {
          title: this.$t('操作'),
          key: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ]
      return list
    },
    baseUnit() {
      return this.unitList?.[0]?.unitNameFull
    },
  },
  watch: {
    defaultTableData: {
      immediate: true,
      handler(newVal) {
        // console.log('newVal: ', newVal)
        if (newVal.length) {
          if (newVal.length && (newVal.length > 0 || newVal[0].skuStyleList?.length > 0)) {
            this.noSku = false
          } else {
            this.$set(newVal[0], 'styleTypeName', this.$t(`无规格`))
          }
          this.setSkuItem(newVal)
        }
      },
    },
    styleTypeList: {
      handler(newVal) {
        if (newVal?.length) {
          this.addSkuItem(newVal)
        }
      },
      immediate: true,
    },
  },
  data() {
    return {
      noSku: true,
      sku_item_list: [],
      tableData: [],
      previewImage: '',
      propertiesVisible: false,
      previewVisible: false,
      currentSku: {},
    }
  },
  filters: {
    notSelected2(value, sku_item_list) {
      if (!value.length) return []
      let value_list = []
      sku_item_list.forEach((item) => {
        item.value_list.forEach((elem) => {
          value_list.push(elem)
        })
      })
      return value.filter((item) => {
        return !value_list.find((elem) => elem.styleTypeName === item.styleTypeName)
      })
    },
  },
  methods: {
    onChange() {
      this.$nextTick(() => {
        this.$emit('changeList', 'speed')
      })
    },
    setSkuItem(list) {
      for (let i = 0; i < list.length; i++) {
        let item = list[i],
          totalStyleType = [],
          totalStyleTypeName = []
        item?.skuStyleList?.forEach((elem) => {
          totalStyleType.push(elem.styleType)
          totalStyleTypeName.push(elem.styleValue)
        })
        list[i].styleType = totalStyleType.join('\\')
        list[i].styleTypeName =
          list[i].styleTypeName === this.$t(`无规格`)
            ? list[i].styleTypeName
            : totalStyleTypeName.join('\\')
      }
      this.tableData = deepClone(this.originalData)
      this.$nextTick(() => {
        this.tableData = [...list]
      })
    },
    addSkuItem(styleTypeList) {
      let isDraft = localStorage.getItem('isDraft')
      if (styleTypeList.length === 0) {
        this.noSku = true
        return
      }
      if (isDraft) return
      let list = []
      this.noSku = false
      styleTypeList.forEach((item) => {
        let styleValueList = item.styleList.map((elem) => ({
          styleTypeName: elem.styleValue,
          styleId: elem.styleId,
        }))
        const data = {
          styleType: item.styleType,
          styleTypeName: item.styleTypeName,
          textAvailable: item.textAvailable,
          imageAvailable: item.imageAvailable,
          value_list: item.value_list || [], //规格值列表
          styleValueList, //规格可选值列表
        }
        let hasItem = this.sku_item_list.find((elem) => elem.styleType === item.styleType)
        if (hasItem?.value_list?.length > 0) {
          data.value_list = hasItem.value_list
        }
        list.push(data)
      })
      this.sku_item_list = [...list]
      this.setTableData()
    },
    changeSubChildren(index1, index2, value) {
      let item = this.sku_item_list[index1].value_list[index2]
      item.styleTypeName = value.styleTypeName
      this.setTableData()
      this.$nextTick(() => {
        this.$emit('changeList', 'speed')
      })
    },
    addSubChildren(item, textAvailable) {
      item.value_list.push({
        styleTypeName: '',
        textAvailable,
        uploadImageUrlList: [],
      })
    },
    deleteSkuItem(index1, index2) {
      if (!this.is_edit) return
      if (index2 !== undefined) {
        if (
          this.page === 'platformGoodsDetail' &&
          this.tableData.length === 1 &&
          this.customTitle.indexOf('\\') === -1
        ) {
          this.$warning(this.$t('SKU列表不得为空'))
          return
        }
        this.sku_item_list[index1].value_list.splice(index2, 1)
        if (this.sku_item_list[index1].value_list.length === 0) {
          this.noSku = true
        }
      } else {
        this.sku_item_list.splice(index1, 1)
      }
      if (this.tableData.length === 1) {
        this.tableData[0].skuStyleList = []
        this.tableData[0].styleDesc = ''
        this.tableData[0].styleType = ''
        this.tableData[0].styleTypeName = ''
      }
      this.setTableData()
    },
    setTableData() {
      let sub_list = []
      this.sku_item_list.forEach((item) => {
        let arr = []
        item.value_list
          .filter((value_item) => value_item.styleTypeName)
          .forEach((elem) => {
            arr.push({ styleType: item.styleType, ...elem })
          })
        if (arr.length) {
          sub_list.push(arr)
        }
      })
      if (!sub_list.length) return

      let tableData = this.doExchange(sub_list)
      this.tableData = deepClone(this.originalData)
      this.$nextTick(() => {
        this.tableData = tableData
      })
    },
    submitData() {
      // console.log(this.tableData)
      return new Promise((reslove, reject) => {
        let err_msg = ''
        for (let i = 0; i < this.sku_item_list.length; i++) {
          let item = this.sku_item_list[i]
          if (err_msg) {
            break
          }
          if (item.value_list.filter((item) => !item.styleTypeName).length > 0) {
            err_msg = `${item.styleTypeName}${this.$t('规格值')}${this.$t('不能为空')}`
          }
        }
        for (let i = 0; i < this.tableData.length; i++) {
          let item = this.tableData[i]
          if (err_msg) {
            break
          }
          if (item.price !== 0 && !item.price) {
            err_msg = `${item.styleTypeName}${this.$t('售价')}${this.$t('必填')}`
          }
          // if (!item.brandSku) { //商品货号非必填
          //   err_msg = `${item.styleTypeName}${this.$t(
          //     "商品货号"
          //   )}${this.$t("必填")}`;
          // }
          if (!item.skuCode) {
            err_msg = `${item.styleTypeName}${this.$t('商家编码')}${this.$t('必填')}`
          }
          if (!item.barcode) {
            err_msg = `${item.styleTypeName}${this.$t('商品条码')}${this.$t('必填')}`
          }
        }
        if (err_msg && this.page !== 'platformGoodsDetail' && !this.speed) {
          this.$warning(err_msg)
          reject()
        } else {
          reslove({
            styleTypeList: this.sku_item_list,
            sku_list: this.tableData,
          })
        }
      })
    },
    removeSku() {
      this.sku_item_list = []
      //tabledata 不能为空，此操作视为清空表格
      this.tableData = deepClone(this.originalData)
    },
    copyPrevSkuImage(list, index) {
      if (!this.is_edit) return
      list[index].uploadImageUrlList = JSON.parse(
        JSON.stringify(list[index - 1].uploadImageUrlList)
      )
    },
    doExchange(doubleArrays) {
      let len = doubleArrays.length
      if (len >= 2) {
        let len1 = doubleArrays[0].length
        let len2 = doubleArrays[1].length
        let newlen = len1 * len2
        let temp = new Array(newlen)
        let index = 0
        for (let i = 0; i < len1; i++) {
          for (let j = 0; j < len2; j++) {
            temp[index] = {
              styleType: doubleArrays[0][i].styleType + '\\' + doubleArrays[1][j].styleType,
              styleTypeName:
                doubleArrays[0][i].styleTypeName + '\\' + doubleArrays[1][j].styleTypeName,
            }
            index++
          }
        }
        let newArray = new Array(len - 1)
        newArray[0] = temp
        if (len > 2) {
          let _count = 1
          for (let i = 2; i < len; i++) {
            newArray[_count] = doubleArrays[i]
            _count++
          }
        }
        return this.doExchange(newArray)
      } else {
        let len1 = doubleArrays[0].length
        let temp = []
        let index = 0
        for (let i = 0; i < len1; i++) {
          let styleTypeName = doubleArrays[0][i].styleTypeName
          // 修改匹配逻辑:将规格值按字母顺序排序后比较
          const sortedStyleTypeName = styleTypeName.split('\\').sort().join('\\')
          let thisTableData = this.tableData.find((item) => {
            const itemSortedStyleTypeName = item.styleTypeName.split('\\').sort().join('\\')
            return itemSortedStyleTypeName === sortedStyleTypeName
          })
          if (thisTableData) {
            temp[index] = {
              ...thisTableData,
              styleType: doubleArrays[0][i].styleType,
              styleTypeName, // 保持原始顺序的规格名
            }
            index++
          } else if (this.is_edit) {
            temp[index] = {
              ...this.originalData[0],
              styleType: doubleArrays[0][i].styleType,
              styleTypeName,
            }
            index++
          }
        }
        return temp
      }
    },
    copySku(name) {
      let tableData = deepClone(this.tableData)
      tableData.forEach((item) => {
        item[name] = this.tableData[0][name]
      })
      this.$nextTick(() => {
        this.tableData = tableData
      })
    },
    toggleImage(item, bol) {
      this.$set(item, 'hide', bol)
    },
    saveDraft() {
      localStorage.setItem('sku_item_list', JSON.stringify(this.sku_item_list))
      localStorage.setItem('tableData', JSON.stringify(this.tableData))
    },
    loadDraft() {
      let sku_item_list = localStorage.getItem('sku_item_list'),
        tableData = localStorage.getItem('tableData')
      if (sku_item_list) {
        this.sku_item_list = JSON.parse(sku_item_list)
      }
      if (tableData) {
        this.tableData = JSON.parse(tableData)
      }
    },
    getDelImageId(item, list) {
      this.$set(item, 'uploadImageUrlList', [...list])
    },
    showProperties(record) {
      this.currentSku = record
      this.$nextTick(() => {
        this.$refs.propertiesModal.open()
      })
    },
    addUnitList() {
      let list = deepClone(this.unitList)
      list.push({
        unitName: undefined,
        unitNameFull: undefined,
        conversionRate: this.unitList.length === 0 ? 1 : undefined,
        baseUnit: this.unitList.length === 0 ? true : false,
        saleUnit: false,
        procurementUnit: false,
        inventoryUnit: false,
        otherUnit: false,
      })
      this.$emit('update:unitList', list)
    },
    delUnit(index) {
      let list = deepClone(this.unitList)
      list.splice(index, 1)
      this.$emit('update:unitList', list)
    },
    changeCheck(key, record) {
      let list = deepClone(this.unitList)
      list.forEach((item) => {
        if (record.unitName !== item.unitName) {
          item[key] = false
        }
      })
      this.$emit('update:unitList', list)
    },
    changeUnitHandle(data, record) {
      record.unitNameFull = data.nameFull
    },
    changeSuccess(properties) {
      this.currentSku.properties = properties
    },
    handleInput: debounce(function (event, record, name) {
      const value = event.target.value
      const regex = /^[a-zA-Z0-9-]*$/ // 允许的字符：数字和英文字母
      if (!regex.test(value)) {
        // 如果输入的字符不符合要求，则从字符串中移除不合法的字符
        record[name] = value.replace(/[^a-zA-Z0-9-]/g, '')
      } else {
        record[name] = value
      }
    }),
    deleteRow(index) {
      if (this.tableData.length === 1) {
        this.$warning(this.$t('SKU列表不得为空'))
        return
      }
      this.tableData.splice(index, 1)
      this.$emit('changeList', 'speed')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@css/mixin.scss';
.round {
  border-radius: 10%;
}
.children-list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;

  .sub-child {
    width: 200px;
    margin-right: 10px;

    .select-content {
      align-items: center;

      .trash {
        color: #999;
      }
    }

    .ml10 {
      cursor: pointer;
    }
  }

  &.wrap {
    flex-direction: column;

    .sub-child {
      width: 100%;
      margin-bottom: 10px;
    }
  }
}
.sku-table {
  margin-top: 10px;
}
.text-info {
  cursor: pointer;
}
.btn {
  padding-left: 0;
  margin-left: 0;
  margin-right: 10px;
}
.ant-upload-picture-card-wrapper {
  display: flex;
}
.h40 {
  height: 40px;
}
.sku-label {
  min-width: 60px;
}
.tips {
  font-size: 12px;
  color: $font-color-light;
}
.red {
  margin-right: 2px;
  font-style: normal;
  color: #ff0000;
}
.delete-icon {
  margin-left: 8px;
  color: #999;
  cursor: pointer;
  &:hover {
    color: #f5222d;
  }
}
</style>

<style lang="scss">
.sku-setting {
  .ant-card-body {
    overflow: auto;
    padding: 12px 24px;
    height: 180px;
  }
  .ant-upload-list {
    display: flex;
  }
  .table-header {
    line-height: initial;
    display: flex;
    flex-wrap: wrap;
    .btn {
      cursor: pointer;
      &:hover {
        color: $main;
      }
    }
  }
}
.add-unit {
  width: 100%;
  border: 1px dotted #ddd;
  padding: 4px 0;
  font-size: 14px;
  margin-top: 10px;
  cursor: pointer;
}
</style>
